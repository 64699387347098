<template>
  <div class="c_pingbi_time_pop">
    <el-dialog title="新增骗子" width="900px" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" :model="formData" label-width="120px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="平台类型:">
                <el-select v-model="form.platform_id">
                  <el-option v-for="item in platFormList" :key="item.id" :label="item.title" :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="平台账号:">
                <el-input v-model="form.account" placeholder="请输入平台账号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="姓名:">
                <el-input  v-model="form.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="手机号:">
                <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="身份证号:">
                <el-input v-model="form.card_id" placeholder="请输入身份证号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="银行卡号:">
                <el-input v-model="form.card_number" placeholder="请输入银行卡号"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="10">
              <el-form-item label="支付宝号:">
                <el-input v-model="form.alipay_number" placeholder="请输入支付宝号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="微信:">
                <el-input v-model="form.wechat" placeholder="请输入微信"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="QQ:">
                <el-input v-model="form.qq" placeholder="请输入QQ"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="加入骗子库原因:">
                <el-input type="textarea" placeholder="请输入内容" :autosize="{ minRows: 3, maxRows: 6 }" v-model="form.remark" maxlength="100" show-word-limit/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="save()">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiAddPianzi } from "@/utils/api.js";
export default {
  props: {
    platFormList: {
      type: Array,
      default() {
        [ { id: 'ALL', title: '全部'} ]
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 86400000);
        }
      },
      seeStatus:1,
      show: false,

      formData: { },
      form: {
        platform_id: 'ALL',
        account: '',
        name: '',
        mobile: '',
        card_id: '',
        card_number: '',
        alipay_number: '',
        wechat: '',
        qq: '',
        remark: ''
      },
    }
  },
  methods: {
    // status //1 审核 2查看
    onShow(data = { id: '' }, status) {
      this.seeStatus = status
      console.log(this.formData)
      this.formData = data
      this.show = true
    },
    onClose() {
      this.show = false
      this.form = {
        platform_id: 'ALL',
        account: '',
        name: '',
        mobile: '',
        card_id: '',
        card_number: '',
        alipay_number: '',
        wechat: '',
        qq: '',
        remark: ''
      }
    },
    save() {
      this.apiAddPianziHandel()
    },
    // 新增骗子
    async apiAddPianziHandel() {
      let loading = this.$loading()
      const { success, data } = await apiAddPianzi(this.form).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.onClose()
      console.log(data)
    }
  }
}
</script>

<style lang='scss'>

</style>