<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>骗子库列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="danger" @click="templateDownload">下载模板</el-button>
        <el-button size="mini" type="success" @click="setForm">新增骗子</el-button>
        <el-button size="mini" type="primary" @click="importFileClick">批量导入</el-button>
        <el-button size="mini" type="info" @click="exportClick">导出骗子库</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="平台类型">
        <el-select v-model="query.platform_id" @change="getList()">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="用户ID" value="user_id"></el-option>
          <el-option label="手机号" value="mobile"></el-option>
          <el-option label="第三方账号" value="account"></el-option>
          <el-option label="姓名" value="name"></el-option>
          <el-option label="微信" value="wechat"></el-option>
          <el-option label="QQ" value="qq"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="真实姓名">
          <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
          </template>
      </el-table-column>
      <el-table-column label="平台类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.platform_id | filtersPlatName(platFormList) }}</span>
          </template>
      </el-table-column>
      <el-table-column label="第三方账号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.account }}</span>
          </template>
      </el-table-column>
      <el-table-column label="手机号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.mobile }}</span>
          </template>
      </el-table-column>
      <el-table-column label="银行卡号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.card_id }}</span>
          </template>
      </el-table-column>
      <el-table-column label="支付宝号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.alipay_number }}</span>
          </template>
      </el-table-column>
       <el-table-column label="身份证号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.card_number }}</span>
          </template>
      </el-table-column>
      <el-table-column label="微信" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.wechat }}</span>
        </template>
      </el-table-column>
      <el-table-column label="QQ" :show-overflow-tooltip="true">
         <template slot-scope="scope">
          <span>{{ scope.row.qq }}</span>
        </template>
      </el-table-column>

      <el-table-column label="加入骗子库原因" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <el-input type="textarea" readonly :autosize="{ minRows: 1, maxRows: 2}" placeholder="请输入内容" v-model="scope.row.remark"></el-input>
          </template>
      </el-table-column>
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="apiRemoveBlackHandel(scope.row)">移出骗子库</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <UserPianziListPop :platFormList="platFormList" @emitSaveSuccess="getList" ref="UserPianziListPop"/>

    <!-- 批量导入 -->
    <user-pianzi-list-upload ref="UserPianziListUpload" @importSuccess="getList"/>

    <!-- 导出骗子库 -->
    <user-pianzi-list-export-pop ref="UserPianziListExportPop" :platFormList="platFormList"/>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetBlackList, apiGetShopTypeList, apiRemoveBlack } from '@/utils/api.js'
  import UserPianziListPop from './UserPianziListPop.vue'
  import UserPianziListUpload from './UserPianziListUpload.vue'
  // import UserListPingbiTimePop from './UserListPingbiTimePop.vue'
  import UserPianziListExportPop from './UserPianziListExportPop.vue'
  export default {
    components: { UserPianziListPop, UserPianziListUpload, UserPianziListExportPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },
        platFormList: [ { id: 'ALL', title: '全部'},  { id: '0', title: '其他'}  ],

        query: { search_key: 'user_id', search_val: '', platform_id: 'ALL',  page:1, limit: 15 },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {},

        // 模板下载地址
        templateDownUrl: '/upload/black.xlsx'
      }
    },
    async created() {
      await this.apiGetShopTypeListHandel()
      this.getList()
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersPlatName(id, list) {
      let obj = list.find(item => item.id == id)
      if(obj) return obj.title
      return ''
    },
    },
    methods: {
      formatDate,
      // 导出骗子库
      exportClick() {
        this.$refs.UserPianziListExportPop.onShow()
      },

      // 点击批量导入
      importFileClick() {
        this.$refs.UserPianziListUpload.uploadClick()
      },
      
      // 移出骗子库
      async apiRemoveBlackHandel(item) {
        this.$confirm('此操作将会移出骗子库, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } =  await apiRemoveBlack({ id: item.id }).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(() => {});
      },
      // 获取平台列表
      async apiGetShopTypeListHandel() {
        const { success, data } = await apiGetShopTypeList()
        if(!success) return
        this.platFormList = this.platFormList.concat(data.list)
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetBlackList(this.query).catch(() => this.table.loading = false)
        this.table.loading = false
        if(!success) return
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status = 1) {
        if(!formData.id) return this.$refs.UserPianziListPop.onShow({ id: '' }, status)
        this.formData = JSON.parse(JSON.stringify(formData))
      },
      // 点击重置
      resetData() {
        this.query = { search_key: 'user_id', search_val: '', platform_id: 'ALL',  page:1, limit: 15 }
        this.getList()
      },

      // 下载模板
      templateDownload() {
        if(!this.templateDownUrl) return
        let loading = this.$loading()
        let a = document.createElement('a')
        a.setAttribute('href', this.templateDownUrl)
        a.setAttribute('download', "")
        a.click()
        loading.close()
      },
    }
  }
</script>

<style lang="scss">
</style>