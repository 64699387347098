<template>
  <div class="s_user_pianzi_upload">
    <!--  :multiple="true" -->
    <el-upload id="upload-button" action="" :http-request="uploadFile" :show-file-list="false" :before-upload="beforeUpload" :accept="uploadAccept"></el-upload>
  </div>
</template>

<script>
import { apiPianziImportBlack } from '@/utils/api.js'
export default {
  data() {
    return {
      uploadAccept: ".xls, .csv, .xlsx",
    }
  },
  methods: {
    // 上传
    uploadClick() {
      document.getElementById('upload-button').getElementsByClassName('el-upload__input')[0].click()
    },
    // 导入
    async uploadFile(param) {
      console.log(param)
      let formData = new FormData()
      formData.append('file', param.file)
      const loading = this.$loading()
      const { success, data } = await apiPianziImportBlack(formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('importSuccess')
    },

    // 上传前 return false 即停止上传
    beforeUpload(file) {
      console.log(file)
      return true
    }

  }
}
</script>

<style lang='scss'>

</style>